import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PGMLService } from './services/pgml.service';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';

import { LoginComponent } from './components/login/login.component';
import { ConsoleComponent } from './components/console/console.component';

import { AppsComponent } from './components/apps/apps.component';
import { RolesComponent } from './components/roles/roles.component';
import { RoleComponent } from './components/role/role.component';
import { OperationsComponent } from './components/operations/operations.component';
import { OperationComponent } from './components/operation/operation.component';

import { EnvironmentsComponent } from './components/environments/environments.component';
import { EnvironmentComponent } from './components/environment/environment.component';
import { EnvironmentEditComponent } from './components/environment-edit/environment-edit.component';
import { AppsuitesComponent } from './components/appsuites/appsuites.component';

import { AppsuiteseditComponent } from './components/appsuitesedit/appsuitesedit.component';
import { GlobalsService } from './services/globals.service';
import { ManagenodeComponent } from './components/managenode/managenode.component';
import { AppsuiteAddComponent } from './components/appsuite-add/appsuite-add.component';
import { AppsuiteLockComponent } from './components/appsuite-lock/appsuite-lock.component';
import { AppsuiteDeleteComponent } from './components/appsuite-delete/appsuite-delete.component';
import { RolesAddComponent } from './components/roles-add/roles-add.component';
import { RolesAssignComponent } from './components/roles-assign/roles-assign.component';
import { RoleOperationsComponent } from './components/role-operations/role-operations.component';
import { RoleUsersComponent } from './components/role-users/role-users.component';
import { CoreService } from './services/core.service';
import { CodeGeneratorComponent } from './components/code-generator/code-generator.component';
import { AppsAddComponent } from './components/apps-add/apps-add.component';


//> NAV
import { ComponentsModule } from './components/nav/components.module';
import { AppsUsersComponent } from './components/apps-users/apps-users.component';
import { AppsLockComponent } from './components/apps-lock/apps-lock.component';
import { AppsDeleteComponent } from './components/apps-delete/apps-delete.component';
import { AppsUserRolesComponent } from './components/apps-user-roles/apps-user-roles.component';


@NgModule({
  declarations: [


    AppComponent,

    LoginComponent,
    ConsoleComponent,

    AppsComponent,
    RolesComponent,
    RoleComponent,
    OperationsComponent,
    OperationComponent,
    EnvironmentsComponent,
    EnvironmentComponent,
    EnvironmentEditComponent,
    AppsuitesComponent,

    AppsuiteseditComponent,
    ManagenodeComponent,
    AppsuiteAddComponent,
    AppsuiteLockComponent,
    AppsuiteDeleteComponent,
    RolesAddComponent,
    RolesAssignComponent,
    RoleOperationsComponent,
    RoleUsersComponent,
    CodeGeneratorComponent,
    AppsAddComponent,
    AppsUsersComponent,
    AppsLockComponent,
    AppsDeleteComponent,
    AppsUserRolesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ComponentsModule,
    BrowserAnimationsModule,
    HttpClientModule,   
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
    },
    PGMLService,
    GlobalsService,
    CoreService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseLocation() {
  const paths: string[] = location.pathname.split('/').splice(1, 1);
  const basePath: string = (paths && paths[0]) || '';
  return '/' + basePath;
}