import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppsComponent } from './components/apps/apps.component';;
import { RolesComponent } from './components/roles/roles.component';
import { OperationsComponent } from './components/operations/operations.component';
import { EnvironmentsComponent } from './components/environments/environments.component';
import { LoginComponent } from './components/login/login.component';
import { AppsuitesComponent } from './components/appsuites/appsuites.component';
import { AppsuiteseditComponent } from './components/appsuitesedit/appsuitesedit.component';
import { ManagenodeComponent } from './components/managenode/managenode.component';
import { ConsoleComponent } from './components/console/console.component';
import { AppsuiteAddComponent } from './components/appsuite-add/appsuite-add.component';
import { AppsuiteLockComponent } from './components/appsuite-lock/appsuite-lock.component';
import { AppsuiteDeleteComponent } from './components/appsuite-delete/appsuite-delete.component';
import { RolesAddComponent } from './components/roles-add/roles-add.component';
import { RolesAssignComponent } from './components/roles-assign/roles-assign.component';
import { CodeGeneratorComponent } from './components/code-generator/code-generator.component';
import { AppsLockComponent } from './components/apps-lock/apps-lock.component';
import { AppsAddComponent } from './components/apps-add/apps-add.component';
import { AppsDeleteComponent } from './components/apps-delete/apps-delete.component';
import { AppsUsersComponent } from './components/apps-users/apps-users.component';
import { AppsUserRolesComponent } from './components/apps-user-roles/apps-user-roles.component';


const routes: Routes = [

  { path: 'environments',        component:  EnvironmentsComponent},
  { path: 'login',               component:  LoginComponent},
  { path: '', redirectTo: '/environments', pathMatch: 'full'},

  { path:  'console',           component:  ConsoleComponent,
      children: 
    [
      {path: 'manageappsuites',                  component: AppsuitesComponent,       outlet: "managePane"},
      {path: 'managenode/:app_suite_id',         component: ManagenodeComponent,      outlet: "managePane"},
      {path: 'codegenerator/:app_suite_id',      component: CodeGeneratorComponent,   outlet: "managePane"},
      {path: 'manageroles/:app_suite_id',        component: RolesComponent,           outlet: "managePane"},


      {path: 'manageapplications/:app_suite_id', component: AppsComponent,            outlet: "managePane"},
      
      {path: 'addappsuite',                      component: AppsuiteAddComponent,     outlet: "managePane"},
      {path: 'deleteappsuite/:app_suite_id',     component: AppsuiteDeleteComponent,  outlet: "managePane"},
      {path: 'lockappsuite/:app_suite_id',       component: AppsuiteLockComponent,    outlet: "managePane"},

      {path: 'lockapplication/:app_config_id',          component: AppsLockComponent,         outlet: "managePane"},
      {path: 'deleteapplication/:app_config_id',        component: AppsDeleteComponent,       outlet: "managePane"},
      {path: 'addapplication/:app_suite_id',            component: AppsAddComponent,          outlet: "managePane"},
      {path: 'applicationusers/:app_config_id',         component: AppsUsersComponent,        outlet: "managePane"},
      {path: 'userroles/:user_id',                      component: AppsUserRolesComponent,        outlet: "managePane"},

    ]},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
