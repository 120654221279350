import { Component, OnInit } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { Router } from '@angular/router';
import { GlobalsService } from '../../services/globals.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss']
})
export class ConsoleComponent implements OnInit {

  
  appSuites = null;
  apps = null;
  users = null;

  busy_getting_users = false;
  busy_getting_asssuites = false;
  busy_getting_apps = false;
  
  getAppSuitesSub;
  getUsersSub;
  getAppsSub;


  constructor(private srv: PGMLService, private router: Router, private coreSrv: CoreService, private globalsSrv: GlobalsService) { 
 
  }


  ngOnInit() {

    if(this.srv.getURL()==null || this.srv.loggedin_status==false){
      this.router.navigate(['/environments']);
      return;
    }

    this.getAppSuitesSub = this.coreSrv.getAppSuitesComplete.subscribe(res=>{
      this.appSuites = res;
      console.log('this.appsuites:',this.appSuites.length)
      this.busy_getting_asssuites = false;
    });

    //this.getAppsSub = this.coreSrv.getAppsComplete.subscribe(res=>{
    //  this.apps = res;
    //  this.busy_getting_apps = false;
    //});

    //this.getUsersSub = this.coreSrv.getUsersComplete.subscribe(res=>{
    //  this.users = res;
    //  this.busy_getting_users = false;
    //});

    this.refreshAppSuites();
    //this.refreshApps();
    //this.refreshUsers();

    this.router.navigate(['/console', { outlets: { "managePane": ["manageappsuites"] } }]);

  }

  //------------------------------------------------------------------------------------------------------------------

  manageAppSuites(){
    this.router.navigate(['/console', { outlets: { "managePane": ["manageappsuites"] } }]);
  }

  //------------------------------------------------------------------------------------------------------------------

  refreshAppSuites(){
    this.busy_getting_asssuites = true;
    this.coreSrv.getAppSuites(this.srv.getURL());
  }


  //------------------------------------------------------------------------------------------------------------------

  refreshApps(){
    //this.busy_getting_apps = true;
    //this.coreSrv.getApps(this.srv.getURL());
  }

  //------------------------------------------------------------------------------------------------------------------

  refreshUsers(){
    //this.busy_getting_users = true;
    //this.coreSrv.getUsers(this.srv.getURL());
  }


  //------------------------------------------------------------------------------------------------------------------

  manageNode(){
    this.router.navigate(['/console', { outlets: { "managePane": ["managenode"] } }]);
    this.globalsSrv.setSelectedAppSuite(null);
  }

  //------------------------------------------------------------------------------------------------------------------

  managePaymentProcessors(){
    this.router.navigate(['/console', { outlets: { "managePane": ["managepaymentprocessors"] } }]);
  }

  //------------------------------------------------------------------------------------------------------------------

  addAppSuite(){
    this.router.navigate(['/console', { outlets: { "managePane": ["addappsuite"] } }]);
  }

  //------------------------------------------------------------------------------------------------------------------

  manageRoles(apps){
    
    this.router.navigate(['/console', { outlets: { "managePane": ["manageroles", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);

  }

  //------------------------------------------------------------------------------------------------------------------

  manageApplications(apps){
    
    this.router.navigate(['/console', { outlets: { "managePane": ["manageapplications", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);

  }

  //------------------------------------------------------------------------------------------------------------------

  codeGenerator(){
  
    this.router.navigate(['/console', { outlets: { "managePane": ["codeGenerator"] } }]);

  }
  
  //------------------------------------------------------------------------------------------------------------------

  lockAppSuite(apps){
    
    this.router.navigate(['/console', { outlets: { "managePane": ["lockappsuite", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);

  }

  //------------------------------------------------------------------------------------------------------------------

  deleteAppSuite(apps){
    
    this.router.navigate(['/console', { outlets: { "managePane": ["deleteappsuite", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);

  }

}