import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-role-operations',
  templateUrl: './role-operations.component.html',
  styleUrls: ['./role-operations.component.scss']
})
export class RoleOperationsComponent implements OnInit {

  _selectedRole;
  
  @Input('selectedRole')
  set selectedRole(value) {
      this._selectedRole = value;
      this.refreshRoleOperations();
  }

  @Output() assignOperation = new EventEmitter;

  operations;
  busy = false;

  constructor(private srv: PGMLService, private coreSrv: CoreService) { }

  ngOnInit() {
    this.busy = false;
    this.refreshRoleOperations();
  }

  //-----------------------------------------------------------------------

  refreshRoleOperations(){
    this.busy = true;
    this.coreSrv.getRoleOperations(this.srv.getURL(), this._selectedRole.role_id).then(res=>{
      this.operations = res;
      this.busy = false;
    })
  }

  //-----------------------------------------------------------------------

  unassign(o){
    this.coreSrv.unassignOperationFromRole(this.srv.getURL(), this._selectedRole.role_id, o.operation_id).then(res=>{
      this.busy = false;
      this.refreshRoleOperations();
    });
  }

  //-----------------------------------------------------------------------

  onAssignOperation(){
    this.assignOperation.emit(this._selectedRole);
  }
  
}
