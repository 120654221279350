import { Component, OnInit } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-appsuite-add',
  templateUrl: './appsuite-add.component.html',
  styleUrls: ['./appsuite-add.component.scss']
})
export class AppsuiteAddComponent implements OnInit {


  busy = false;

  app_suites;

  getAppSuitesSub;

  app_suite_name="";
  app_suite_description="";
  app_suite_add_error="";
  app_suite_prefix = "";
  app_suite_url = "";
  app_config_template = "";
  add_app_suite=false;

  constructor(private srv: PGMLService, private router: Router, private coreSrv: CoreService,private location: Location,) { }

  ngOnInit() {
    this.add_app_suite = true;
  }
  
  cancel(){
    this.add_app_suite = false;
    this.app_suite_name="";
    this.app_suite_description="";
    this.app_suite_prefix="";
    this.app_suite_url= "";
    this.app_config_template = "";
    this.location.back();
  }
  

  add(){
     //TODO> Validate

    //> Add the App suite
    this.busy = true;
    this.coreSrv.addAppSuite(this.srv.getURL(),{
        "app_suite_name": this.app_suite_name, 
        "app_suite_description": this.app_suite_description, 
        "app_suite_prefix":this.app_suite_prefix,
        "app_suite_url":this.app_suite_url,
        "app_config_template": this.app_config_template
      }).then(res=>{
     
      this.app_suite_description = "";
      this.app_suite_name = "";
      this.app_suite_prefix="";
      this.app_suite_url="";
      this.app_suite_add_error = "";
      this.app_config_template = "";
      this.app_suites = res;
      this.add_app_suite = false;
      this.coreSrv.getAppSuites(this.srv.getURL()).then(res=>{
        this.busy = false;
        this.location.back();
      });
    });
  }

}
