import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-role-users',
  templateUrl: './role-users.component.html',
  styleUrls: ['./role-users.component.scss']
})
export class RoleUsersComponent implements OnInit {

  _selectedRole;
  
  @Output() assignUsers = new EventEmitter;

  @Input('selectedRole')
  set selectedRole(value) {
      this._selectedRole = value;
      this.refreshRoleUsers();
  }

  users;
  busy = false;

  constructor(private srv: PGMLService, private coreSrv: CoreService) { }

  ngOnInit() {
    this.busy = false;
    this.refreshRoleUsers();
  }


  refreshRoleUsers(){
    this.busy = true;
    this.coreSrv.getRoleUsers(this.srv.getURL(), this._selectedRole.role_id).then(res=>{
      this.users = res;
      this.busy = false;
    })
  }

  unassignUser(u){
    this.busy = true;
    this.coreSrv.unassignUserFromRole(this.srv.getURL(), u.user_id, this._selectedRole.role_id).then(res=>{
      this.refreshRoleUsers();
    });

  }

  
  //-----------------------------------------------------------------------

  onAssignUser(){
    this.assignUsers.emit();
  }

}
