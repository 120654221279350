import { Component, OnInit, ElementRef } from '@angular/core';
//import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import { PGMLService } from 'src/app/services/pgml.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
      mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;

    constructor(location: Location,  private element: ElementRef, private router: Router, public srv: PGMLService) {
      this.location = location;
          this.sidebarVisible = false;
    }


    ngOnInit(){
      this.listTitles = new Array(); //ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }

    //----------------------------------------------------------------------------
    //----------------------------------------------------------------------------

    logout(){
        this.srv.logOut();
        this.router.navigate(['/environments']);
    }

    //----------------------------------------------------------------------------
    //----------------------------------------------------------------------------

}
