import { Component, OnInit } from '@angular/core';
import { PGMLService } from 'src/app/services/pgml.service';
import { GlobalsService } from 'src/app/services/globals.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor( private srv: PGMLService, private globalsSrv: GlobalsService) { }

  ngOnInit() {
    //this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  isMobileMenu() {
     if (this.srv.screenWidth > 991) {
          return false;
     }
      return true;
  };
}
