import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { GlobalsService } from '../../services/globals.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-roles-add',
  templateUrl: './roles-add.component.html',
  styleUrls: ['./roles-add.component.scss']
})
export class RolesAddComponent implements OnInit {


  busy = false;
  role_name=""


  @Input('selectedAppSuite') selectedAppSuite;
  @Output() cancelled = new EventEmitter;
  @Output() rolesAdded = new EventEmitter;

  constructor(private srv: PGMLService, private router: Router,private location: Location, private coreSrv: CoreService) { }

  ngOnInit() {
  }
  
  cancel(){
    this.role_name = "";
    this.cancelled.emit();
  }
  

  add(){
     //TODO> Validate

    if(!this.selectedAppSuite){
      this.location.back();
      return;
    }

    //> Add the App suite
    this.busy = true;
  
    this.coreSrv.createRole(this.srv.getURL(), {role_name: this.role_name, app_suite_id: this.selectedAppSuite.app_suite_id} ).then(res=>{
     
      this.role_name = ""
      this.coreSrv.getAppSuites(this.srv.getURL()).then(res=>{
        this.busy = false;
        this.rolesAdded.emit();
      });
    });
  }

}
