import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PGMLService } from 'src/app/services/pgml.service';
import { Router } from '@angular/router';
import { CoreService } from '../../services/core.service';
import { LoginResult } from '../../services/pgml.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public srv: PGMLService, private coreSrv: CoreService, private router: Router) { }

  loginname='';
  password='';
  error_message = '';

  @Output() loggedin = new EventEmitter();

  ngOnInit() {

    if(this.srv.getURL()==null){
      this.router.navigate(['/environments']);
    }

  }

  login(){

    console.log('Loggin in');

    this.coreSrv.login( this.srv.getURL(),{username:this.loginname,password:this.password, app_id: this.srv.app_id}).then(res=>{
        if(!res){
          this.error_message='Could not login';
          return;
        }else{
          this.error_message = '';
          localStorage.setItem("user_token",(res as LoginResult).token);
          localStorage.setItem("user_data",JSON.stringify((res as LoginResult).user));
          this.srv.loggedin_status = true;
          this.router.navigate(['/console']);
          this.loggedin.emit();
        }
    });

  }

  cancel(){
    this.router.navigate(['/environments']);
  }

}
