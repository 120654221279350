import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { GlobalsService } from '../../services/globals.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-apps-add',
  templateUrl: './apps-add.component.html',
  styleUrls: ['./apps-add.component.scss']
})
export class AppsAddComponent implements OnInit {


  busy = false;
  app_name="";
  app_config = "";

  @Input('selectedAppSuite') selectedAppSuite;
  @Output() cancelled = new EventEmitter;
  @Output() appAdded = new EventEmitter;

  constructor(private srv: PGMLService, private router: Router,private location: Location, private coreSrv: CoreService, public globalsSrv: GlobalsService) {

    console.log('this.globalsSrv.getSelectedAppSuite().app_suite_id:', this.globalsSrv.getSelectedAppSuite().app_suite_id);

   }

  ngOnInit() {
  }
  
  cancel(){
    this.app_name = "";
    this.app_config = "";
    this.cancelled.emit();
    this.location.back();
  }
  

  add(){

    console.log('adding application');

    this.busy = true;
  
    this.coreSrv.createAppForAppSuite(this.srv.getURL(), this.globalsSrv.getSelectedAppSuite().app_suite_id, {app_name: this.app_name, app_config: this.app_config } ).then(res=>{
     
      this.app_name = "";
      this.app_config  = "";

      this.coreSrv.getAppSuites(this.srv.getURL()).then(res=>{
        this.busy = false;
        this.appAdded.emit();
        this.location.back();
      });
    });
    
  }

}
