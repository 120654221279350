
import { Component, ViewChild, ElementRef } from '@angular/core';
import { PGMLService } from './services/pgml.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {


  title = 'PGMLAdmin';



  constructor(private srv: PGMLService, private router: Router){}

  ngOnInit(){
   
  }

 
}
