import { Component, OnInit } from '@angular/core';
import { PGMLService } from 'src/app/services/pgml.service';
import { CoreService } from '../../services/core.service';
import { GlobalsService } from 'src/app/services/globals.service';


@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  opps = null;
  busy = false;

  constructor(private srv: PGMLService, private coreSrv: CoreService, private globalsSrv: GlobalsService) { }


  ngOnInit() {
    this.busy = true;
    this.coreSrv.getOperations(this.srv.getURL(), this.globalsSrv.getSelectedAppSuite().app_suite_id).then(res=>{
      this.opps = res;
      this.busy = false;
    })
  }

}
