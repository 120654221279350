import { Component, OnInit } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { CoreService } from '../../services/core.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-code-generator',
  templateUrl: './code-generator.component.html',
  styleUrls: ['./code-generator.component.scss']
})
export class CodeGeneratorComponent implements OnInit {

  service_name = "";
  service_code_template = "";
  busy = false;

  constructor(private srv: PGMLService, private coreSrv: CoreService, public globalsSrv: GlobalsService) { }

  ngOnInit() {
  }


  generateCode(){
    
    this.busy = true;

    this.coreSrv.getClientSideCodeTemplateForService(this.srv.getURL(), this.globalsSrv.getSelectedAppSuite().app_suite_id, this.service_name).then(res=>{

      this.service_code_template = " import { Injectable, Output, EventEmitter } from '@angular/core'; <br>" +
                                 " import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'; <br>" +
                                 " @Injectable() <br>" +
                                 " export class " + this.service_name.trim() + "Service { <br>" +
                                 " constructor(private _http: HttpClient) {} <br>";

      var template = "";
    
      template = (res as any).concat_templates;

      template = template.replace(/\@Output\(\)/g,'<br><br>@Output()');
      template = template.replace(/new EventEmitter\(\);/g,'new EventEmitter();<br>');

      this.service_code_template += template;

      this.service_code_template += "<br>}";

      this.busy = false;

    });
    
  }
}
