
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { environment } from '../../environments/environment';

export class PGMLApp {
    app_config_id;
    app_id;
    app_name;
    app_suite_id;
    app_suite_feature_id;
    user_id;
    locked_ind;
    create_date;
    create_by;
    current_ind;
}


export class LoginResult {
  public token:any;
  public user: any;
}


@Injectable()
export class PGMLService {

  private _url: string;
  prod_mode;
  app_id;
  loggedin_status = false;

  constructor(private _http: HttpClient) {

        this._url = null;
        this.prod_mode = environment.production;
        this.app_id = environment.appId;

        if(!this.prod_mode) console.log('PGMLService:this.prod_mode:',this.prod_mode );
        //if(!this.prod_mode) console.log('PGMLService:this._url:',this._url );
        if(!this.prod_mode) console.log('PGMLService:this.app_id:',this.app_id);

        this.environments = JSON.parse(localStorage.getItem('pgml_admin_environments'));

        if(!this.environments){
            this.environments = [];
        }else{
            console.log('PGMLService:environments:', this.environments);
        }

    }

    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    // ENVIRONMENTS
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

    selected_environment = null;
    environments=[];

    
    addEnvironment(env){
        this.environments.push(env);
        localStorage.setItem("pgml_admin_environments", JSON.stringify (this.environments));
    }

    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

    removeEnvironment(nickname){
        var i = 0;
        for(i=0;i<this.environments.length;i++){
            if(this.environments[i].nickname==nickname){
                this.environments.splice(i);
            }
        }
        localStorage.setItem("pgml_admin_environments", JSON.stringify(this.environments));
    }

    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

    setEnvironment(env){
        this.selected_environment = env;
        this._url = env.url;
    }

    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

    setURL(url){
        this._url = url;
    }

    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

    getURL(){
        return this._url;
    }

    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

    @Output() screenSizeChanged = new EventEmitter();

    screenHeight;
    screenWidth;
    
    getScreenSize(event?) {
        
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;

        var screen = {"height":this.screenHeight, "width":this.screenWidth};

        this.screenSizeChanged.emit(screen);

    }

    
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

    logOut(){

        localStorage.removeItem("user_token");
        localStorage.removeItem("user_data");
        this.loggedin_status = false;

    }


//---------------------------------------------------------------------------------
// END SERVICE
}