import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { Router } from '@angular/router';
import { PGMLService } from '../../services/pgml.service';
import {Location} from '@angular/common';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-appsuite-delete',
  templateUrl: './appsuite-delete.component.html',
  styleUrls: ['./appsuite-delete.component.scss']
})
export class AppsuiteDeleteComponent implements OnInit {
  busy;

  constructor(private srv: PGMLService, private router: Router, private coreSrv: CoreService ,private location: Location,public globalsSrv: GlobalsService) { }

  //----------------------------------------------------------------------

  ngOnInit() {
  }

  //----------------------------------------------------------------------

  cancel(){
    this.location.back();
  }

  //----------------------------------------------------------------------

  deleteAppSuite(){
      //> Add the App suite
      this.busy = true;
      
      this.coreSrv.deleteAppSuite(this.srv.getURL(),this.globalsSrv.getSelectedAppSuite().app_suite_id).then(res=>{
       
        this.coreSrv.getAppSuites(this.srv.getURL()).then(res=>{
          this.busy = false;
          this.globalsSrv.setSelectedAppSuite(null);
          this.router.navigate(['/console', { outlets: { "managePane": ["managenode"] } }]);
        });
      });
    }
  }