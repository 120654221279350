
import { Injectable, Output, EventEmitter } from '@angular/core';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

@Injectable()
export class GlobalsService {

    private _selected_app_suite;
    
    public node_db_settings;
    public node_routes;
    public node_db_operations;
    public node_db_app_suites;
    public node_db_roles;
    public node_db_apps;

//---------------------------------------------------------------------------------
//---------------------------------------------------------------------------------

    constructor() {}

//---------------------------------------------------------------------------------
//---------------------------------------------------------------------------------


    @Output() appSuiteSelected = new EventEmitter;

    setSelectedAppSuite(selected_app_suite){
        this._selected_app_suite = selected_app_suite;
        this.appSuiteSelected.emit();
    }

//---------------------------------------------------------------------------------
//---------------------------------------------------------------------------------

    getSelectedAppSuite(){
        return this._selected_app_suite;
    }

//---------------------------------------------------------------------------------
//---------------------------------------------------------------------------------

    menuRoutes: RouteInfo[];

    setSideMenu(menuRoutes: RouteInfo[]){
        this.menuRoutes = menuRoutes;
    }

    getSideMenu(){
        return this.menuRoutes;
    }


//---------------------------------------------------------------------------------
// END SERVICE
}