import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PGMLService } from '../../services/pgml.service';
import {Location} from '@angular/common';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-apps-user-roles',
  templateUrl: './apps-user-roles.component.html',
  styleUrls: ['./apps-user-roles.component.scss']
})
export class AppsUserRolesComponent implements OnInit {

  busy;
  user_id;
  appl = null;
  user = null;

  user_roles = null;
  app_suite_roles = null;

  constructor(private srv: PGMLService, private router: Router, private route: ActivatedRoute, private coreSrv: CoreService,private location: Location,private globalsSrv: GlobalsService) {


   }

  //----------------------------------------------------------------------

  ngOnInit() {

    this.user_id = this.route.snapshot.paramMap.get('user_id');

    console.log('user_id:', this.user_id);
    
    this.busy = true;
    
    this.coreSrv.getUser(this.srv.getURL(), this.user_id).then(res=>{
      this.user=res;
      
      console.log(" USER : ", this.user);

      this.refreshUserRoles();
    
    });


  }

  //----------------------------------------------------------------------

  cancel(){
    this.location.back();
  }

  //----------------------------------------------------------------------

  refreshUserRoles(){

    this.busy = true;

    this.coreSrv.getUserRoles(this.srv.getURL(), this.user.user_id).then(res=>{

      this.user_roles = res;
      this.app_suite_roles = [];
      console.log(this.user_roles);

      this.coreSrv.getRolesForAppSuite(this.srv.getURL(), this.globalsSrv.getSelectedAppSuite().app_suite_id).then((res:any)=>{

        var found = false;

        for(var i = 0 ; i < res.length; i ++){
          found = false;
          
          if(this.user_roles && this.user_roles.length>0){

            for(var x = 0; x < this.user_roles.length; x ++){
              if(this.user_roles[x].role_id==res[i].role_id){
                found = true;
              }
            }

          }

          if(!found){
            this.app_suite_roles.push(res[i]);
          }
        }

      });

    });

    this.busy = false;

  }

  //----------------------------------------------------------------------

  assignRole(r){

      this.busy = true;
      
       this.coreSrv.assignRoleToUser(this.srv.getURL(), this.user.user_id, r.role_id, null).then(res=>{
        this.refreshUserRoles();
       });

  }

  //----------------------------------------------------------------------

  unassignRole(r){

    this.busy = true;
    
    this.coreSrv.unassignUserFromRole(this.srv.getURL(), this.user.user_id, r.role_id).then(res=>{
      this.refreshUserRoles();
    });

  }

}