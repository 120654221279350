import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { CoreService } from '../../services/core.service';
import { GlobalsService } from '../../services/globals.service';


@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  _selectedRole;
  
  @Input('selectedRole')
  set selectedRole(value) {
      this._selectedRole = value;
      this.ngOnInit();
  }

  @Input('app_suite_id') app_suite_id;

  @Output() roleUnassigned = new EventEmitter;
  @Output() roleRemoved = new EventEmitter;
  @Output() roleLocked = new EventEmitter;

  show_ops_users = true;
  show_remove = false
  show_lock = false;
  show_unassign = false;

  show_assign_operations = false;
  busy_assign_operations=false;
  assign_operations;

  show_assign_users = false;
  busy_assign_users=false;
  assign_users;

  constructor(private srv: PGMLService, private coreSrv: CoreService, public globalsSrv: GlobalsService) { }

  ngOnInit() {
    this.show_ops_users = true;
    this.show_remove = false
    this.show_lock = false;
    this.show_unassign = false;
    this.show_assign_operations = false;
    this.show_assign_users = false;
  }

  //-----------------------------------------------------------
  showLock(){
    this.show_ops_users = false;
    this.show_remove = false
    this.show_lock = true;
    this.show_unassign = false;
    this.show_assign_operations = false;
    this.show_assign_users = false;
  }

  //-----------------------------------------------------------
  showRemove(){
    this.show_ops_users = false;
    this.show_remove = true
    this.show_lock = false;
    this.show_unassign = false;
    this.show_assign_operations = false;
    this.show_assign_users = false;
  }
  
  //-----------------------------------------------------------
  showUnassign(){
    this.show_ops_users = false;
    this.show_remove = false
    this.show_lock = false;
    this.show_unassign = true;
    this.show_assign_operations = false;
    this.show_assign_users = false;
  }

  //-----------------------------------------------------------
  cancel(){
    this.show_ops_users = true;
    this.show_remove = false
    this.show_lock = false;
    this.show_unassign = false;
    this.show_assign_operations = false;
    this.show_assign_users = false;
  }

  //-----------------------------------------------------------
  @Output() cancelled = new EventEmitter();

  cancelForm(){
    this.cancelled.emit();
  }
  
  //-----------------------------------------------------------
  busy_unassign = false;

  unassign(){
    this.busy_unassign = true;
    this.coreSrv.unassignRoleFromAppSuite(this.srv.getURL(), this._selectedRole.role_id, this.app_suite_id ).then(res=>{
      this.busy_unassign = false;
      this.roleUnassigned.emit();
    })
  }

  //-----------------------------------------------------------
  busy_remove = false;

  remove(){
    this.busy_remove = true;
    this.coreSrv.removeRole(this.srv.getURL(), this._selectedRole.role_id).then(res=>{
      this.busy_remove = false;
      this.roleUnassigned.emit();
    })
  }

  //-----------------------------------------------------------
  busy_lock = false;

  lock(){
    this.busy_lock = true;
    this.coreSrv.lockRole(this.srv.getURL(), this._selectedRole.role_id, null).then(res=>{
      this.busy_lock = false;
      this.roleUnassigned.emit();
    })
  }

  //-----------------------------------------------------------
  all_operations;
  role_operations;

  onAssignOperation(){
    this.show_ops_users = false;
    this.show_remove = false
    this.show_lock = false;
    this.show_unassign = false;
    this.show_assign_operations = true;
    this.show_assign_users = false;

    this.busy_assign_operations = true;

    this.assign_operations = [];
    var op_found = false;

    this.coreSrv.getOperations(this.srv.getURL(), this.globalsSrv.getSelectedAppSuite().app_suite_id).then(res=>{
      this.busy_assign_operations = false;
      this.all_operations = res; 
      this.coreSrv.getRoleOperations(this.srv.getURL(), this._selectedRole.role_id).then(res=>{
        this.role_operations = res;
        for(var i = 0; i<this.all_operations.length;i++){
          op_found = false;
          for(var x = 0; x<this.role_operations.length;x++){
            if(this.all_operations[i].operation_id == this.role_operations[x].operation_id){
              op_found = true;
            }
          }
          if(!op_found){
            this.assign_operations.push(this.all_operations[i]);
          }
        }
      })
    })
  }

  //-----------------------------------------------------------
  exitAssignOperations(){
    this.show_ops_users = true;
    this.show_remove = false
    this.show_lock = false;
    this.show_unassign = false;
    this.show_assign_operations = false;
    this.show_assign_users = false;

    this.assign_operations = null;
    this.all_operations = null;
    this.role_operations = null;
  }

  //-----------------------------------------------------------
  assignOperation(o){

    this.busy_assign_operations = true;

    this.coreSrv.assignOperationToRole(this.srv.getURL(), this._selectedRole.role_id, o.operation_id, null).then(res=>{
      this.busy_assign_operations = false;
      this.exitAssignOperations();
    })
  }


 //-----------------------------------------------------------
  all_users;
  role_users;
  apps;

  getApps(){

    this.apps = null;

    this.show_ops_users = false;
    this.show_remove = false
    this.show_lock = false;
    this.show_unassign = false;
    this.show_assign_operations = false;
    this.show_assign_users = true;

    this.coreSrv.getAppsForAppSuite(this.srv.getURL(), this.globalsSrv.getSelectedAppSuite().app_suite_id).then(res=>{
      this.apps = res;
    });
    
  }


  onAssignUsers(app_id){
   

    this.busy_assign_users = true;

    this.assign_users = [];
    var op_found = false;

    this.coreSrv.getUsersForApp(this.srv.getURL(), app_id).then(res=>{
      this.busy_assign_users = false;
      this.all_users = res; 
      this.coreSrv.getRoleUsers(this.srv.getURL(), this._selectedRole.role_id).then(res=>{
        this.role_users= res;
        for(var i = 0; i<this.all_users.length;i++){
          op_found = false;
          for(var x = 0; x<this.role_users.length;x++){
            if(this.all_users[i].user_id == this.role_users[x].user_id){
              op_found = true;
            }
          }
          if(!op_found){
            this.assign_users.push(this.all_users[i]);
          }
        }
      })
    })
  }

  //-----------------------------------------------------------
  assignUsers(o){

    this.busy_assign_users = true;

    this.coreSrv.assignRoleToUser(this.srv.getURL(), o.user_id, this._selectedRole.role_id,  null).then(res=>{
      this.busy_assign_operations = false;
      this.exitAssignOperations();
    })
  }


}
