import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { Router } from '@angular/router';
import { PGMLService } from '../../services/pgml.service';
import {Location} from '@angular/common';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-appsuite-lock',
  templateUrl: './appsuite-lock.component.html',
  styleUrls: ['./appsuite-lock.component.scss']
})
export class AppsuiteLockComponent implements OnInit {

  busy;
  user;

  constructor(private srv: PGMLService, private router: Router, private coreSrv: CoreService,private location: Location,public globalsSrv: GlobalsService) { 
    
    this.user = JSON.parse(localStorage.getItem('user_data'));

  }

  //----------------------------------------------------------------------

  ngOnInit() {
  }

  //----------------------------------------------------------------------

  cancel(){
    this.location.back();
  }

  //----------------------------------------------------------------------

  lockAppSuite(){
      //> Add the App suite
      this.busy = true;
      
      this.coreSrv.lockAppSuite(this.srv.getURL(), this.globalsSrv.getSelectedAppSuite().app_suite_id, null ).then(res=>{
       
        this.coreSrv.getAppSuites(this.srv.getURL()).then(res=>{
          this.busy = false;
          this.location.back();
        });
      });
    }


}

