import { Component, OnInit , OnDestroy} from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService } from '../../services/globals.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {

 
  apps = null;
  busy = false;
  app_suite_id = null;
  selected_app = null;
  operation_header = "Select a app or action";

  subscribe_roles;
  subscribe_selected_suite;

  show_dummy = true;
  show_add = false;
  show_assign = false;
  show_app = false;

  constructor(private srv: PGMLService,private router: Router,  private route: ActivatedRoute, public globalsSrv: GlobalsService, private coreSrv: CoreService) { 
    
    this.subscribe_selected_suite = this.globalsSrv.appSuiteSelected.subscribe(res=>{
      
      this.show_dummy = true;
      this.show_add = false;
      this.show_assign = false;
      this.show_app = false;
      this.operation_header = "Select an app or action";

      if(this.globalsSrv.getSelectedAppSuite()){
        this.getApps(this.globalsSrv.getSelectedAppSuite().app_suite_id);
      }

    });

  }

  //------------------------------------------------------------------------
  ngOnInit() {

    this.operation_header = "Select a app or action"; 
    this.busy = true;
    this.app_suite_id = this.route.snapshot.paramMap.get('app_suite_id');
    console.log(">> ROLES this.app_suite_id:", this.globalsSrv.getSelectedAppSuite().app_suite_id);

    this.getApps(this.globalsSrv.getSelectedAppSuite().app_suite_id);

  }

  //------------------------------------------------------------------------
  ngOnDestroy(){

    this.subscribe_selected_suite.unsubscribe();

  }

  //------------------------------------------------------------------------------------------------------------------

  lockApp(apps){
    this.router.navigate(['/console', { outlets: { "managePane": ["lockapplication", apps.app_config_id] } }]);
  }

  //------------------------------------------------------------------------------------------------------------------

  removeApp(apps){
    this.router.navigate(['/console', { outlets: { "managePane": ["deleteapplication", apps.app_config_id] } }]);
  }

  //------------------------------------------------------------------------------------------------------------------

  addApp(){
    this.router.navigate(['/console', { outlets: { "managePane": ["addapplication", this.globalsSrv.getSelectedAppSuite().app_suite_id] } }]);
  }
  
  //------------------------------------------------------------------------------------------------------------------
  
  loadUsers(apps){
    this.router.navigate(['/console', { outlets: { "managePane": ["applicationusers", apps.app_config_id] } }]);
  }

  //------------------------------------------------------------------------
  getApps(app_suite_id){

    this.busy = true;

    this.coreSrv.getAppsForAppSuite(this.srv.getURL(), app_suite_id).then(res=>{
      this.apps = res;
      this.busy = false;
    });

  }

  //------------------------------------------------------------------------
  loadApp(r){
    this.operation_header = "App View";
    console.log(r);
    this.show_dummy = false;
    this.show_add = false;
    this.show_app = true;
    this.show_assign = false;
    this.selected_app = r;
  }

  //------------------------------------------------------------------------
  addNew(){
    this.operation_header = "Add New App";
    this.show_dummy = false;
    this.show_add = true;
    this.show_assign = false;
    this.show_app = false;
  }

  //------------------------------------------------------------------------
  assignExisting(){
    this.operation_header = "Assign a Role to App Suite";
    this.show_dummy = false;
    this.show_add = false;
    this.show_assign = true;
    this.show_app = false;
  }

  //------------------------------------------------------------------------
  addCancel(){
    this.show_dummy = true;
    this.show_add = false;
    this.show_assign = false;
    this.show_app = false;
  }

  //------------------------------------------------------------------------
  appAdded(){
    this.show_dummy = true;
    this.show_add = false;
    this.show_assign = false;
    this.show_app = false;

    this.getApps(this.globalsSrv.getSelectedAppSuite().app_suite_id);

  }

  //------------------------------------------------------------------------
  roleAssigned(){
    this.getApps(this.globalsSrv.getSelectedAppSuite().app_suite_id);
  }

  //------------------------------------------------------------------------
  roleUnassigned(){
    this.show_dummy = true;
    this.show_add = false;
    this.show_assign = false;
    this.show_app = false;
    this.getApps(this.globalsSrv.getSelectedAppSuite().app_suite_id);
  }

  //------------------------------------------------------------------------
  appRemoved(){
    this.show_dummy = true;
    this.show_add = false;
    this.show_assign = false;
    this.show_app = false;
    this.getApps(this.globalsSrv.getSelectedAppSuite().app_suite_id);
  }

  //------------------------------------------------------------------------
  appLocked(){
      this.show_dummy = true;
      this.show_add = false;
      this.show_assign = false;
      this.show_app = false;
      this.getApps(this.globalsSrv.getSelectedAppSuite().app_suite_id);
    }

}