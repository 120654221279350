import { Component, OnInit } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { Router } from '@angular/router';
import { CoreService } from '../../services/core.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-appsuites',
  templateUrl: './appsuites.component.html',
  styleUrls: ['./appsuites.component.scss']
})
export class AppsuitesComponent implements OnInit {

  appSuites = null;
  busy = false;

  app_suites;

  getAppSuitesSub;

  app_suite_name="";
  app_suite_description="";
  app_suite_add_error="";

  add_app_suite=false;

  constructor(private srv: PGMLService, private router: Router, private coreSrv: CoreService, private globalsSrv: GlobalsService) { }


  ngOnInit() {

    this.refreshAppSuites();

  }

  //------------------------------------------------------------------------------------------------------------------

  loadRoles(apps){
  
    this.router.navigate(['/console', { outlets: { "managePane": ["manageroles", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);

  }

  //------------------------------------------------------------------------------------------------------------------

  async checkStatus(apps){

    try{
      var routes : any = await this.coreSrv.getRoutes(apps.app_suite_url);
      if(!routes || routes.length==0){
        return false;
      }else{
        return true;
      }
    }catch(e){
      console.log('ERROR:', e.message);
      return false;
    }

  }

  //------------------------------------------------------------------------------------------------------------------

  loadRoutes(apps){
    this.router.navigate(['/console', { outlets: { "managePane": ["managenode", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);
  }

  //------------------------------------------------------------------------------------------------------------------

  loadAppSuite(app_suite_id){
    this.router.navigate(['/console', { outlets: { "managePane": ["manageappsuites",  { outlets: { "editAppsuitesPane": ["manageappsuite", app_suite_id] } }] } }]);
  }

  //------------------------------------------------------------------------------------------------------------------

  manageApplications(apps){
    
    this.router.navigate(['/console', { outlets: { "managePane": ["manageapplications", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);

  }

  //------------------------------------------------------------------------------------------------------------------

  lockAppSuite(apps){
  
    this.router.navigate(['/console', { outlets: { "managePane": ["lockappsuite", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);

  }

  //------------------------------------------------------------------------------------------------------------------

  addAppSuite(){
    this.router.navigate(['/console', { outlets: { "managePane": ["addappsuite"] } }]);
  }

  //------------------------------------------------------------------------------------------------------------------

  deleteAppSuite(apps){
    
    this.router.navigate(['/console', { outlets: { "managePane": ["deleteappsuite", apps.app_suite_id] } }]);
    this.globalsSrv.setSelectedAppSuite(apps);

  }
  
  //------------------------------------------------------------------------------------------------------------------

  refreshAppSuites(){

    this.busy=true;

    this.coreSrv.getAppSuites(this.srv.getURL()).then(async (res : any)=>{

      for(var i = 0; i<res.length ; i++){
        var status = await this.checkStatus(res[i]);
        if(status){
          res[i].ping_status = 'Active';
        }else{
          res[i].ping_status = 'Not Active'
        }
      }

      this.app_suites = res;
      this.busy=false;
    });
  }

  //------------------------------------------------------------------------------------------------------------------

  cancel(){
    this.add_app_suite = false;
    this.app_suite_name="";
    this.app_suite_description="";
  }
  
  //------------------------------------------------------------------------------------------------------------------

  add(){
     //TODO> Validate

    //> Add the App suite
    this.busy = true;
    this.coreSrv.addAppSuite(this.srv.getURL(), {"app_suite_name": this.app_suite_name, "app_suite_description": this.app_suite_description} ).then(res=>{
      this.coreSrv.getAppSuites(this.srv.getURL()).then(res=>{
        this.busy = false;
        this.app_suite_description = "";
        this.app_suite_name = "";
        this.app_suite_add_error = "";
        this.app_suites = res;
        this.add_app_suite = false;
      });
    });
  }
}