import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss']
})
export class EnvironmentComponent implements OnInit {

  @Input() envObj;

  @Output() removeEnvironment = new EventEmitter;
  @Output() loadEnvironment = new EventEmitter;

  constructor() { }

  ngOnInit() {
  }

  removeEnv(){
    this.removeEnvironment.emit(this.envObj);
  }

  loadEnv(){
    this.loadEnvironment.emit(this.envObj);
  }
}
