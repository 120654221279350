import { Component, OnInit } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-environments',
  templateUrl: './environments.component.html',
  styleUrls: ['./environments.component.scss']
})
export class EnvironmentsComponent implements OnInit {

  show_add = false;

  constructor(public srv: PGMLService, private router: Router) { }

  ngOnInit() {
  }

  addCancel(){
    this.show_add = false;
  }

  addEnv(env){
    this.srv.addEnvironment(env);
    this.show_add = false;
  }

  removeEnv(env){
    this.srv.removeEnvironment(env.nickname);
  }

  loadEnv(env){
    console.log("LOAD ENV", env);
    this.srv.setEnvironment(env);
    this.router.navigate(["/login"]);
  }
}
