import { Component, OnInit } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { CoreService } from '../../services/core.service';


@Component({
  selector: 'app-appsuitesedit',
  templateUrl: './appsuitesedit.component.html',
  styleUrls: ['./appsuitesedit.component.scss']
})
export class AppsuiteseditComponent implements OnInit {

  app_suite_id;
  busy=false;

  constructor( public srv: PGMLService, public route: ActivatedRoute, private location: Location, private coreSrv: CoreService, private router: Router) { }

  ngOnInit() {
    this.app_suite_id = this.route.snapshot.paramMap.get('app_suite_id');
  }

  //--------------------------------------------

  cancel(){
    this.location.back();
  }

  //--------------------------------------------

  app_suite_name="";
  app_suite_description="";
  app_suite_add_error;

  add(){

    //TODO> Validate

    //> Add the App suite
    this.busy = true;
    this.coreSrv.addAppSuite(this.srv.getURL(), {"app_suite_name": this.app_suite_name, "app_suite_description": this.app_suite_description} ).then(res=>{
      this.coreSrv.getAppSuites(this.srv.getURL()).then(res=>{
        this.busy = false;
        this.app_suite_description = "";
        this.app_suite_name = "";
        this.app_suite_add_error = "";
        this.location.back();
      });
    });

  }

  //---------------------------------------------

  manageApplications(){

    this.router.navigate(['/console', 
      { outlets: { "managePane": 
        ["manageappsuites",  
          { outlets: { "editAppsuitesPane": 
            ["manageappsuite", this.app_suite_id, {outlets:{"appsuitePane":["manageapplications",this.app_suite_id]}}] } 
          }
        ] } 
      }
    ]);
 
  }

    //---------------------------------------------

    manageRoles(){

      this.router.navigate(['/console', 
      { outlets: { "managePane": 
        ["manageappsuites",  
          { outlets: { "editAppsuitesPane": 
            ["manageappsuite", this.app_suite_id, {outlets:{"appsuitePane":["manageroles",this.app_suite_id]}}] } 
          }
        ] } 
      }
    ]);

    }

}
