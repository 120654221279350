import { Component, OnInit , OnDestroy} from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService } from '../../services/globals.service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  roles = null;
  busy = false;
  app_suite_id = null;
  selected_role = null;
  operation_header = "Select a role or action";

  subscribe_roles;
  subscribe_selected_suite;

  show_dummy = true;
  show_add = false;
  show_assign = false;
  show_role = false;

  constructor(private srv: PGMLService,private router: Router,  private route: ActivatedRoute, public globalsSrv: GlobalsService, private coreSrv: CoreService) { 
    
    this.subscribe_selected_suite = this.globalsSrv.appSuiteSelected.subscribe(res=>{
      
      this.show_dummy = true;
      this.show_add = false;
      this.show_assign = false;
      this.show_role = false;
      this.operation_header = "Select a role or action";

      if(this.globalsSrv.getSelectedAppSuite()){
        this.loadRoles(!this.globalsSrv.getSelectedAppSuite()?0:this.globalsSrv.getSelectedAppSuite().app_suite_id);
      }

    });

  }

  //------------------------------------------------------------------------
  ngOnInit() {

    this.operation_header = "Select a role or action"; 
    this.busy = true;
    this.app_suite_id = this.route.snapshot.paramMap.get('app_suite_id');
    console.log(">> ROLES this.app_suite_id:", !this.globalsSrv.getSelectedAppSuite()?0:this.globalsSrv.getSelectedAppSuite().app_suite_id);

    this.loadRoles(!this.globalsSrv.getSelectedAppSuite()?0:this.globalsSrv.getSelectedAppSuite().app_suite_id);

  }

  //------------------------------------------------------------------------
  ngOnDestroy(){

    this.subscribe_selected_suite.unsubscribe();

  }

  //------------------------------------------------------------------------
  loadRoles(app_suite_id){

    this.busy = true;

    this.coreSrv.getRolesForAppSuite(this.srv.getURL(), app_suite_id).then(res=>{
      this.roles = res;
      this.busy = false;
    });

  }

  //------------------------------------------------------------------------
  loadRole(r){
    this.operation_header = "Role View";
    console.log(r);
    this.show_dummy = false;
    this.show_add = false;
    this.show_role = true;
    this.show_assign = false;
    this.selected_role = r;
  }

  //------------------------------------------------------------------------
  addNew(){
    this.operation_header = "Add New Role";
    this.show_dummy = false;
    this.show_add = true;
    this.show_assign = false;
    this.show_role = false;
  }

  //------------------------------------------------------------------------
  assignExisting(){
    this.operation_header = "Assign a Role to App Suite";
    this.show_dummy = false;
    this.show_add = false;
    this.show_assign = true;
    this.show_role = false;
  }

  //------------------------------------------------------------------------
  addCancel(){
    this.show_dummy = true;
    this.show_add = false;
    this.show_assign = false;
    this.show_role = false;
  }

  //------------------------------------------------------------------------
  rolesAdded(){
    this.show_dummy = true;
    this.show_add = false;
    this.show_assign = false;
    this.show_role = false;

    this.loadRoles(this.globalsSrv.getSelectedAppSuite().app_suite_id);

  }

  //------------------------------------------------------------------------
  roleAssigned(){
    this.loadRoles(this.globalsSrv.getSelectedAppSuite().app_suite_id);
  }

  //------------------------------------------------------------------------
  roleUnassigned(){
    this.show_dummy = true;
    this.show_add = false;
    this.show_assign = false;
    this.show_role = false;
    this.loadRoles(this.globalsSrv.getSelectedAppSuite().app_suite_id);
  }

  //------------------------------------------------------------------------
  roleRemoved(){
    this.show_dummy = true;
    this.show_add = false;
    this.show_assign = false;
    this.show_role = false;
    this.loadRoles(this.globalsSrv.getSelectedAppSuite().app_suite_id);
  }

}
