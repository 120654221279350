import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PGMLService } from '../../services/pgml.service';
import {Location} from '@angular/common';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-apps-delete',
  templateUrl: './apps-delete.component.html',
  styleUrls: ['./apps-delete.component.scss']
})
export class AppsDeleteComponent implements OnInit {
  busy;
  app_config_id;
  appl = null;
  user = null;

  constructor(private srv: PGMLService, private router: Router, private route: ActivatedRoute, private coreSrv: CoreService,private location: Location,private globalsSrv: GlobalsService) {

    this.app_config_id = this.route.snapshot.paramMap.get('app_config_id');

    this.busy = true;
    this.user = JSON.parse(localStorage.getItem('user_data'));

    this.coreSrv.getApp(this.srv.getURL(), this.app_config_id).then((res:any)=>{
      
      this.busy = false;
      this.appl = res;


    });

   }

  //----------------------------------------------------------------------

  ngOnInit() {
  }

  //----------------------------------------------------------------------

  cancel(){
    this.location.back();
  }

  //----------------------------------------------------------------------

  deleteApp(){

      this.busy = true;
      
      this.coreSrv.removeApp(this.srv.getURL(), this.app_config_id ).then(res=>{
       
        this.coreSrv.getApps(this.srv.getURL()).then(res=>{
          this.busy = false;
          this.location.back();
        });
      });
    }


}

