import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PGMLService } from 'src/app/services/pgml.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService } from '../../services/globals.service';
import { CoreService } from '../../services/core.service';


@Component({
  selector: 'app-roles-assign',
  templateUrl: './roles-assign.component.html',
  styleUrls: ['./roles-assign.component.scss']
})
export class RolesAssignComponent implements OnInit {

  roles = null;
  busy = false;
  app_suite_id = null;
  selected_role = null;

  @Input('selectedAppSuite') selectedAppSuite;
  @Output() roleAssigned = new EventEmitter;
  
  constructor(private srv: PGMLService,private router: Router,  private route: ActivatedRoute, private coreSrv: CoreService) { }


  ngOnInit() {
    this.busy = true;
    this.loadRoles(0);
  }


  //------------------------------------------------------------------------
  loadRoles(app_suite_id){

    this.busy = true;
    this.coreSrv.getRolesForAppSuite(this.srv.getURL(), app_suite_id).then(res=>{
      this.roles = res;
      this.busy = false;
    });
    
  }


  //------------------------------------------------------------------------
  assignRole(r){

    this.busy = true;
    r.busy_assigning = true;
    this.coreSrv.assignRoleToAppSuite(this.srv.getURL(), r.role_id, this.selectedAppSuite.app_suite_id, null).then(res=>{
      r.busy_assigning = false;
      this.loadRoles(0);
      this.roleAssigned.emit();
    });
    
  }

  //------------------------------------------------------------------------

  @Output() cancelled = new EventEmitter();

  cancel(){
    this.cancelled.emit();
  }
  
}
