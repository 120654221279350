import { Component, OnInit, Output , EventEmitter} from '@angular/core';


@Component({
  selector: 'app-environment-edit',
  templateUrl: './environment-edit.component.html',
  styleUrls: ['./environment-edit.component.scss']
})
export class EnvironmentEditComponent implements OnInit {

  env_nickname;
  env_url;

  @Output() addCancelled = new EventEmitter();
  @Output() addEnvironment = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  addEnv(){
    this.addEnvironment.emit({nickname: this.env_nickname, url: this.env_url});
    this.env_url = "";
    this.env_nickname = "";
  }

  cancel(){
    this.env_url = "";
    this.env_nickname = "";
    this.addCancelled.emit();
  }
}
