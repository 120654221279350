import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { CoreService } from '../../services/core.service';
import { GlobalsService } from '../../services/globals.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-apps-users',
  templateUrl: './apps-users.component.html',
  styleUrls: ['./apps-users.component.scss']
})
export class AppsUsersComponent implements OnInit {

  app_config_id;
  app_users = null;
  busy=false;
  appl=null;

  constructor(private srv: PGMLService, private router: Router, private route: ActivatedRoute, private coreSrv: CoreService, private globalsSrv: GlobalsService) { 

    this.app_config_id = this.route.snapshot.paramMap.get('app_config_id');

    this.busy = true;

    this.coreSrv.getApp(this.srv.getURL(), this.app_config_id).then(res=>{
      this.appl=res;
      this.busy = false;
    })
  }

  ngOnInit() {
    this.getAppUsers();
  }

  //--------------------------------------------------------------

  getAppUsers(){
    this.busy=true;
    this.coreSrv.getUsersForApp(this.srv.getURL(),this.app_config_id).then(res=>{
      this.app_users = res;
      this.busy = false;
    })
  }

  //--------------------------------------------------------------
  show_add_user = false;
  user_name = "";
  email = "";
  password = "";
  phonenumber = "";
  firstname = "";
  lastname = "";

  //--------------------------------------------------------------
  onAddUser(){
    this.show_add_user = true;
  }

  //--------------------------------------------------------------
  lockUser(r){
    this.busy = true;
    this.coreSrv.lockUser(this.srv.getURL(), r.user_id, null).then(res=>{
      this.getAppUsers();
    })
  }

  //--------------------------------------------------------------

  loadRoles(u){
    this.router.navigate(['/console', { outlets: { "managePane": ["userroles", u.user_id] } }]);
  }

  //--------------------------------------------------------------
  addUser(){

    this.busy = true;

    var payload = {
      username : this.user_name,
      email : this.email,
      password: this.password,
      phonenumber: this.phonenumber,
      firstname: this.firstname,
      lastname: this.lastname,
      app_id : this.appl.app_id
    };

    this.coreSrv.createUser (this.srv.getURL(),payload).then(res=>{
      this.getAppUsers();
      this.show_add_user = false;
    });


  }

  //--------------------------------------------------------------

 cancel(){
  this.show_add_user = false;
  this.user_name = "";
  this.email = "";
  this.password = "";
  this.phonenumber = "";
  this.firstname = "";
  this.lastname = "";
 }
}
