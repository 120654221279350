import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
@Injectable()
export class CoreService {
constructor(private _http: HttpClient) {}


@Output() getRoutesComplete = new EventEmitter();
getRoutes(node_url) { return new Promise( resolve => { console.log('core:v1:getRoutes:start'); this._http.get(node_url + '/nodet/v1/app_routes/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getRoutesComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getRoutes:error', e); this.getRoutesComplete.emit(null); resolve(null); }); }); }

@Output() getAppSuitesComplete = new EventEmitter();
getAppSuites(node_url) { return new Promise( resolve => { console.log('core:v1:getAppSuites:start'); this._http.get(node_url + '/v1/appsuites/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getAppSuitesComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getAppSuites:error', e); this.getAppSuitesComplete.emit(null); resolve(null); }); }); }

@Output() addAppSuiteComplete = new EventEmitter();
addAppSuite(node_url,payload) { return new Promise( resolve => { console.log('core:v1:addAppSuite:start'); this._http.post(node_url + '/v1/appsuites/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.addAppSuiteComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:addAppSuite:error', e); this.addAppSuiteComplete.emit(null); resolve(null); }); }); }

@Output() lockAppSuiteComplete = new EventEmitter();
lockAppSuite(node_url,app_suite_id,payload) { return new Promise( resolve => { console.log('core:v1:lockAppSuite:start'); this._http.put(node_url + '/v1/appsuites/' + app_suite_id + '/lock/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.lockAppSuiteComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:lockAppSuite:error', e); this.lockAppSuiteComplete.emit(null); resolve(null); }); }); }

@Output() deleteAppSuiteComplete = new EventEmitter();
deleteAppSuite(node_url,app_suite_id) { return new Promise( resolve => { console.log('core:v1:deleteAppSuite:start'); this._http.delete(node_url + '/v1/appsuites/' + app_suite_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.deleteAppSuiteComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:deleteAppSuite:error', e); this.deleteAppSuiteComplete.emit(null); resolve(null); }); }); }

@Output() getAppsForAppSuiteComplete = new EventEmitter();
getAppsForAppSuite(node_url,app_suite_id) { return new Promise( resolve => { console.log('core:v1:getAppsForAppSuite:start'); this._http.get(node_url + '/v1/appsuites/' + app_suite_id + '/apps/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getAppsForAppSuiteComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getAppsForAppSuite:error', e); this.getAppsForAppSuiteComplete.emit(null); resolve(null); }); }); }

@Output() createAppForAppSuiteComplete = new EventEmitter();
createAppForAppSuite(node_url,app_suite_id,payload) { return new Promise( resolve => { console.log('core:v1:createAppForAppSuite:start'); this._http.post(node_url + '/v1/appsuites/' + app_suite_id + '/apps/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.createAppForAppSuiteComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:createAppForAppSuite:error', e); this.createAppForAppSuiteComplete.emit(null); resolve(null); }); }); }

@Output() getAppsComplete = new EventEmitter();
getApps(node_url) { return new Promise( resolve => { console.log('core:v1:getApps:start'); this._http.get(node_url + '/v1/apps/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getAppsComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getApps:error', e); this.getAppsComplete.emit(null); resolve(null); }); }); }

@Output() getAppComplete = new EventEmitter();
getApp(node_url,app_config_id) { return new Promise( resolve => { console.log('core:v1:getApp:start'); this._http.get(node_url + '/v1/apps/' + app_config_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getAppComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getApp:error', e); this.getAppComplete.emit(null); resolve(null); }); }); }

@Output() removeAppComplete = new EventEmitter();
removeApp(node_url,app_config_id) { return new Promise( resolve => { console.log('core:v1:removeApp:start'); this._http.delete(node_url + '/v1/apps/' + app_config_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.removeAppComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:removeApp:error', e); this.removeAppComplete.emit(null); resolve(null); }); }); }

@Output() lockAppComplete = new EventEmitter();
lockApp(node_url,app_config_id,payload) { return new Promise( resolve => { console.log('core:v1:lockApp:start'); this._http.put(node_url + '/v1/apps/' + app_config_id + '/lock/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.lockAppComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:lockApp:error', e); this.lockAppComplete.emit(null); resolve(null); }); }); }

@Output() uploadFileToDiskComplete = new EventEmitter();
uploadFileToDisk(node_url,payload) { return new Promise( resolve => { console.log('core:v1:uploadFileToDisk:start'); this._http.post(node_url + '/v1/upload/file/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.uploadFileToDiskComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:uploadFileToDisk:error', e); this.uploadFileToDiskComplete.emit(null); resolve(null); }); }); }

@Output() uploadFileToDbComplete = new EventEmitter();
uploadFileToDb(node_url,payload) { return new Promise( resolve => { console.log('core:v1:uploadFileToDb:start'); this._http.post(node_url + '/v1/upload/filetodb/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.uploadFileToDbComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:uploadFileToDb:error', e); this.uploadFileToDbComplete.emit(null); resolve(null); }); }); }

@Output() getUploadedFileFromDiskComplete = new EventEmitter();
getUploadedFileFromDisk(node_url) { return new Promise( resolve => { console.log('core:v1:getUploadedFileFromDisk:start'); this._http.get(node_url + '/v1/upload/file/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getUploadedFileFromDiskComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getUploadedFileFromDisk:error', e); this.getUploadedFileFromDiskComplete.emit(null); resolve(null); }); }); }

@Output() getDbDetailsComplete = new EventEmitter();
getDbDetails(node_url) { return new Promise( resolve => { console.log('core:v1:getDbDetails:start'); this._http.get(node_url + '/v1/nodeman/getdbdetails/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getDbDetailsComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getDbDetails:error', e); this.getDbDetailsComplete.emit(null); resolve(null); }); }); }

@Output() getClientSideCodeTemplatesComplete = new EventEmitter();
getClientSideCodeTemplates(node_url,app_suite_id) { return new Promise( resolve => { console.log('core:v1:getClientSideCodeTemplates:start'); this._http.get(node_url + '/v1/nodeman/templates/' + app_suite_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getClientSideCodeTemplatesComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getClientSideCodeTemplates:error', e); this.getClientSideCodeTemplatesComplete.emit(null); resolve(null); }); }); }

@Output() getClientSideCodeTemplateForServiceComplete = new EventEmitter();
getClientSideCodeTemplateForService(node_url,app_suite_id,service) { return new Promise( resolve => { console.log('core:v1:getClientSideCodeTemplateForService:start'); this._http.get(node_url + '/v1/nodeman/templates/' + app_suite_id + '/service/' + service + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getClientSideCodeTemplateForServiceComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getClientSideCodeTemplateForService:error', e); this.getClientSideCodeTemplateForServiceComplete.emit(null); resolve(null); }); }); }

@Output() getRolesComplete = new EventEmitter();
getRoles(node_url) { return new Promise( resolve => { console.log('core:v1:getRoles:start'); this._http.get(node_url + '/v1/roles/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getRolesComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getRoles:error', e); this.getRolesComplete.emit(null); resolve(null); }); }); }

@Output() createRoleComplete = new EventEmitter();
createRole(node_url,payload) { return new Promise( resolve => { console.log('core:v1:createRole:start'); this._http.post(node_url + '/v1/roles/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.createRoleComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:createRole:error', e); this.createRoleComplete.emit(null); resolve(null); }); }); }

@Output() updateRoleComplete = new EventEmitter();
updateRole(node_url,role_id,payload) { return new Promise( resolve => { console.log('core:v1:updateRole:start'); this._http.put(node_url + '/v1/roles/' + role_id + '/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.updateRoleComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:updateRole:error', e); this.updateRoleComplete.emit(null); resolve(null); }); }); }

@Output() removeRoleComplete = new EventEmitter();
removeRole(node_url,role_id) { return new Promise( resolve => { console.log('core:v1:removeRole:start'); this._http.delete(node_url + '/v1/roles/' + role_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.removeRoleComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:removeRole:error', e); this.removeRoleComplete.emit(null); resolve(null); }); }); }

@Output() lockRoleComplete = new EventEmitter();
lockRole(node_url,role_id,payload) { return new Promise( resolve => { console.log('core:v1:lockRole:start'); this._http.put(node_url + '/v1/roles/' + role_id + '/lock/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.lockRoleComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:lockRole:error', e); this.lockRoleComplete.emit(null); resolve(null); }); }); }

@Output() getRolesForAppSuiteComplete = new EventEmitter();
getRolesForAppSuite(node_url,app_suite_id) { return new Promise( resolve => { console.log('core:v1:getRolesForAppSuite:start'); this._http.get(node_url + '/v1/roles/appsuite/' + app_suite_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getRolesForAppSuiteComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getRolesForAppSuite:error', e); this.getRolesForAppSuiteComplete.emit(null); resolve(null); }); }); }

@Output() getRoleUsersComplete = new EventEmitter();
getRoleUsers(node_url,role_id) { return new Promise( resolve => { console.log('core:v1:getRoleUsers:start'); this._http.get(node_url + '/v1/roles/' + role_id + '/users/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getRoleUsersComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getRoleUsers:error', e); this.getRoleUsersComplete.emit(null); resolve(null); }); }); }

@Output() assignUsersToRoleComplete = new EventEmitter();
assignUsersToRole(node_url,role_id,payload) { return new Promise( resolve => { console.log('core:v1:assignUsersToRole:start'); this._http.post(node_url + '/v1/roles/' + role_id + '/users/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.assignUsersToRoleComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:assignUsersToRole:error', e); this.assignUsersToRoleComplete.emit(null); resolve(null); }); }); }

@Output() getRoleOperationsComplete = new EventEmitter();
getRoleOperations(node_url,role_id) { return new Promise( resolve => { console.log('core:v1:getRoleOperations:start'); this._http.get(node_url + '/v1/roles/' + role_id + '/operations/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getRoleOperationsComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getRoleOperations:error', e); this.getRoleOperationsComplete.emit(null); resolve(null); }); }); }

@Output() assignOperationToRoleComplete = new EventEmitter();
assignOperationToRole(node_url,role_id,operation_id,payload) { return new Promise( resolve => { console.log('core:v1:assignOperationToRole:start'); this._http.put(node_url + '/v1/roles/' + role_id + '/operations/' + operation_id + '/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.assignOperationToRoleComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:assignOperationToRole:error', e); this.assignOperationToRoleComplete.emit(null); resolve(null); }); }); }

@Output() unassignOperationFromRoleComplete = new EventEmitter();
unassignOperationFromRole(node_url,role_id,operation_id) { return new Promise( resolve => { console.log('core:v1:unassignOperationFromRole:start'); this._http.delete(node_url + '/v1/roles/' + role_id + '/operations/' + operation_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.unassignOperationFromRoleComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:unassignOperationFromRole:error', e); this.unassignOperationFromRoleComplete.emit(null); resolve(null); }); }); }

@Output() getUserRolesComplete = new EventEmitter();
getUserRoles(node_url,user_id) { return new Promise( resolve => { console.log('core:v1:getUserRoles:start'); this._http.get(node_url + '/v1/users/' + user_id + '/roles/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getUserRolesComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getUserRoles:error', e); this.getUserRolesComplete.emit(null); resolve(null); }); }); }

@Output() assignRoleToUserComplete = new EventEmitter();
assignRoleToUser(node_url,user_id,role_id,payload) { return new Promise( resolve => { console.log('core:v1:assignRoleToUser:start'); this._http.put(node_url + '/v1/users/' + user_id + '/roles/' + role_id + '/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.assignRoleToUserComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:assignRoleToUser:error', e); this.assignRoleToUserComplete.emit(null); resolve(null); }); }); }

@Output() unassignUserFromRoleComplete = new EventEmitter();
unassignUserFromRole(node_url,user_id,role_id) { return new Promise( resolve => { console.log('core:v1:unassignUserFromRole:start'); this._http.delete(node_url + '/v1/users/' + user_id + '/roles/' + role_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.unassignUserFromRoleComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:unassignUserFromRole:error', e); this.unassignUserFromRoleComplete.emit(null); resolve(null); }); }); }

@Output() getOperationsComplete = new EventEmitter();
getOperations(node_url, app_suite_id) { return new Promise( resolve => { console.log('core:v1:getOperations:start'); this._http.get(node_url + '/v1/operations/' + app_suite_id,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getOperationsComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getOperations:error', e); this.getOperationsComplete.emit(null); resolve(null); }); }); }

@Output() createOperationComplete = new EventEmitter();
createOperation(node_url,payload) { return new Promise( resolve => { console.log('core:v1:createOperation:start'); this._http.post(node_url + '/v1/operations/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.createOperationComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:createOperation:error', e); this.createOperationComplete.emit(null); resolve(null); }); }); }

@Output() removeOperationComplete = new EventEmitter();
removeOperation(node_url,operation_id) { return new Promise( resolve => { console.log('core:v1:removeOperation:start'); this._http.delete(node_url + '/v1/operations/' + operation_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.removeOperationComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:removeOperation:error', e); this.removeOperationComplete.emit(null); resolve(null); }); }); }

@Output() lockOperationComplete = new EventEmitter();
lockOperation(node_url,operation_id,payload) { return new Promise( resolve => { console.log('core:v1:lockOperation:start'); this._http.put(node_url + '/v1/operations/' + operation_id + '/lock/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.lockOperationComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:lockOperation:error', e); this.lockOperationComplete.emit(null); resolve(null); }); }); }

@Output() assignRoleToAppSuiteComplete = new EventEmitter();
assignRoleToAppSuite(node_url,role_id,app_suite_id,payload) { return new Promise( resolve => { console.log('core:v1:assignRoleToAppSuite:start'); this._http.put(node_url + '/v1/roles/' + role_id + '/appsuite/' + app_suite_id + '/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.assignRoleToAppSuiteComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:assignRoleToAppSuite:error', e); this.assignRoleToAppSuiteComplete.emit(null); resolve(null); }); }); }

@Output() unassignRoleFromAppSuiteComplete = new EventEmitter();
unassignRoleFromAppSuite(node_url,role_id,app_suite_id) { return new Promise( resolve => { console.log('core:v1:unassignRoleFromAppSuite:start'); this._http.delete(node_url + '/v1/roles/' + role_id + '/appsuite/' + app_suite_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.unassignRoleFromAppSuiteComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:unassignRoleFromAppSuite:error', e); this.unassignRoleFromAppSuiteComplete.emit(null); resolve(null); }); }); }

@Output() streamSoundFileComplete = new EventEmitter();
streamSoundFile(node_url) { return new Promise( resolve => { console.log('core:v1:streamSoundFile:start'); this._http.get(node_url + '/v1/sounds/play/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.streamSoundFileComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:streamSoundFile:error', e); this.streamSoundFileComplete.emit(null); resolve(null); }); }); }

@Output() streamImageFileComplete = new EventEmitter();
streamImageFile(node_url) { return new Promise( resolve => { console.log('core:v1:streamImageFile:start'); this._http.get(node_url + '/v1/images/play/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.streamImageFileComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:streamImageFile:error', e); this.streamImageFileComplete.emit(null); resolve(null); }); }); }

@Output() streamVideoFileComplete = new EventEmitter();
streamVideoFile(node_url) { return new Promise( resolve => { console.log('core:v1:streamVideoFile:start'); this._http.get(node_url + '/v1/videos/play/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.streamVideoFileComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:streamVideoFile:error', e); this.streamVideoFileComplete.emit(null); resolve(null); }); }); }

@Output() streamUploadFileToDiskComplete = new EventEmitter();
streamUploadFileToDisk(node_url,payload) { return new Promise( resolve => { console.log('core:v1:streamUploadFileToDisk:start'); this._http.post(node_url + '/v1/streams/uploadfile/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.streamUploadFileToDiskComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:streamUploadFileToDisk:error', e); this.streamUploadFileToDiskComplete.emit(null); resolve(null); }); }); }

@Output() loginComplete = new EventEmitter();
login(node_url,payload) { return new Promise( resolve => { console.log('core:v1:login:start'); this._http.post(node_url + '/v1/user/login/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.loginComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:login:error', e); this.loginComplete.emit(null); resolve(null); }); }); }

@Output() permissionsCheckComplete = new EventEmitter();
permissionsCheck(node_url,app_id,payload) { return new Promise( resolve => { console.log('core:v1:permissionsCheck:start'); this._http.post(node_url + '/v1/permcheck/' + app_id + '/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.permissionsCheckComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:permissionsCheck:error', e); this.permissionsCheckComplete.emit(null); resolve(null); }); }); }

@Output() getUsersComplete = new EventEmitter();
getUsers(node_url) { return new Promise( resolve => { console.log('core:v1:getUsers:start'); this._http.get(node_url + '/v1/users/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getUsersComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getUsers:error', e); this.getUsersComplete.emit(null); resolve(null); }); }); }

@Output() getUsersForAppComplete = new EventEmitter();
getUsersForApp(node_url,app_config_id) { return new Promise( resolve => { console.log('core:v1:getUsersForApp:start'); this._http.get(node_url + '/v1/users/app/' + app_config_id + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getUsersForAppComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getUsersForApp:error', e); this.getUsersForAppComplete.emit(null); resolve(null); }); }); }

@Output() createUserComplete = new EventEmitter();
createUser(node_url,payload) { return new Promise( resolve => { console.log('core:v1:createUser:start'); this._http.post(node_url + '/v1/users/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.createUserComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:createUser:error', e); this.createUserComplete.emit(null); resolve(null); }); }); }

@Output() updateUserComplete = new EventEmitter();
updateUser(node_url,userId,payload) { return new Promise( resolve => { console.log('core:v1:updateUser:start'); this._http.put(node_url + '/v1/users/' + userId + '/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.updateUserComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:updateUser:error', e); this.updateUserComplete.emit(null); resolve(null); }); }); }

@Output() deleteUserComplete = new EventEmitter();
deleteUser(node_url,userId) { return new Promise( resolve => { console.log('core:v1:deleteUser:start'); this._http.delete(node_url + '/v1/users/' + userId + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.deleteUserComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:deleteUser:error', e); this.deleteUserComplete.emit(null); resolve(null); }); }); }

@Output() getUserComplete = new EventEmitter();
getUser(node_url,userId) { return new Promise( resolve => { console.log('core:v1:getUser:start'); this._http.get(node_url + '/v1/users/' + userId + '/',{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.getUserComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:getUser:error', e); this.getUserComplete.emit(null); resolve(null); }); }); }

@Output() lockUserComplete = new EventEmitter();
lockUser(node_url,userId,payload) { return new Promise( resolve => { console.log('core:v1:lockUser:start'); this._http.put(node_url + '/v1/users/' + userId + '/lock/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.lockUserComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:lockUser:error', e); this.lockUserComplete.emit(null); resolve(null); }); }); }

@Output() webLogSecComplete = new EventEmitter();
webLogSec(node_url,payload) { return new Promise( resolve => { console.log('core:v1:webLogSec:start'); this._http.post(node_url + '/v1/weblog/',payload,{headers: {'Authorization':'Bearer ' + localStorage.getItem('user_token')}}).subscribe((res) => { this.webLogSecComplete.emit(res); resolve(res); }, (e) => { console.log('core:v1:webLogSec:error', e); this.webLogSecComplete.emit(null); resolve(null); }); }); }
}