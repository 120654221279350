import { Component, OnInit } from '@angular/core';
import { PGMLService } from '../../services/pgml.service';
import { GlobalsService } from '../../services/globals.service';
import { CoreService } from '../../services/core.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-managenode',
  templateUrl: './managenode.component.html',
  styleUrls: ['./managenode.component.scss']
})
export class ManagenodeComponent implements OnInit {

  busy = false;

  db_settings;
  node_routes;

  constructor(private router: Router, public srv: PGMLService, private coreSrv: CoreService, public globalsSrv: GlobalsService) { }

  ngOnInit() {
    this.refreshNodeData();
  }

  //-----------------------------------------------------------------------------------------

  installOperation(r){
    console.log(r);
    r.busy_installing = true;
    this.coreSrv.createOperation(this.srv.getURL(), {
        operation_name:r.operation,
        app_suite_id: this.globalsSrv.getSelectedAppSuite().app_suite_id,
        version: r.version,
      }).then(res=>{
      r.busy_installing = false;
      this.refreshNodeData();
    });
  }

  //-----------------------------------------------------------------------------------------

  lockOperation(r){
    r.busy_locking = true;
    this.coreSrv.lockOperation(this.srv.getURL(), r.operation_id, null).then(res=>{
      r.busy_locking = false;
      this.refreshNodeData();
    });
  }

  //-----------------------------------------------------------------------------------------

  codeGen(){
    this.router.navigate(['/console', { outlets: { "managePane": ["codegenerator", this.globalsSrv.getSelectedAppSuite().app_suite_id] } }]);
  }
  //-----------------------------------------------------------------------------------------

  refreshNodeData(){
    this.busy=true;

    //this.coreSrv.getDbDetails(this.srv.getURL()).then(res=>{
      //this.globalsSrv.node_db_settings = res;
      //console.log('db settings: ', res);
      this.coreSrv.getRoutes(this.globalsSrv.getSelectedAppSuite().app_suite_url).then(res=>{
        this.globalsSrv.node_routes = res;
        //console.log('node routes:', res);
        this.coreSrv.getOperations(this.srv.getURL(),this.globalsSrv.getSelectedAppSuite().app_suite_id ).then(res=>{
          this.globalsSrv.node_db_operations = res;
          for(var i =0; i<this.globalsSrv.node_routes.length;i++){
            for(var x=0; x<this.globalsSrv.node_db_operations.length;x++){
              if(
                  this.globalsSrv.node_routes[i].operation=='none' 
                  || 
                  (
                    this.globalsSrv.node_routes[i].operation==this.globalsSrv.node_db_operations[x].operation_name
                    &&
                    this.globalsSrv.node_routes[i].version==this.globalsSrv.node_db_operations[x].version
                  )
                  ){
                this.globalsSrv.node_routes[i].operation_installed = true;
                this.globalsSrv.node_routes[i].operation_id = this.globalsSrv.node_db_operations[x].operation_id;
                this.globalsSrv.node_routes[i].locked_ind = this.globalsSrv.node_db_operations[x].operation_locked;
                this.globalsSrv.node_routes[i].version = this.globalsSrv.node_db_operations[x].version;
              }
            }
          }
          //console.log('node operations:', res);
          this.busy = false;
        });
      });
    //});
  
  }
}
